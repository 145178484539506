.btn-item-dropdown {
  font-size: 16px;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
  background: transparent;
  border: none;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

.btn-item-dropdown:focus {
  background-color: transparent;
}
