.d-flex-justify-center {
  display: flex;
  justify-content: center;
}

.d-flex-row-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-weight: 700;
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  filter: brightness(95%);
}

.inputfile + label {
  cursor: pointer;
}

.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}
