html,
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  height: 100%;
}

a {
  color: rgba(0, 0, 0, 0.7);
}

.custom-btn {
  transition: 0.23s;
  border-radius: 10px;
  font-weight: 500;
}

.custom-btn:hover {
  filter: brightness(95%);
}

.custom-btn.custom-btn-full {
  width: 100%;
}

.browser-default.custom-input {
  font-family: 'Montserrat', sans-serif;
  transition: 0.25s;
  outline: none;
  border: none;
  background-color: rgba(0, 0, 0, 0.03);
  width: 100%;
  border-radius: 10px;
  padding: 20px 30px;
}

.browser-default.custom-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.browser-default.custom-input:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.btn-floating {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

.nav-wrapper.flex {
  display: flex;
  display: -webkit-flex;
}

.nav-wrapper .content-left {
  height: 100%;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.nav-wrapper .content-center {
  height: 100%;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}

.nav-wrapper .content-right {
  height: 100%;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row-reverse;
  align-items: center;
}

.topbar-title {
  font-weight: 500;
  font-size: 18px;
}

.card {
  border-radius: 10px;
}

.card .card-content .card-title {
  font-weight: 500;
  font-size: 20px;
}

.area-base {
  margin-top: 25%;
  margin-bottom: 25%;
}

.collection .collection-item.avatar .title {
  font-weight: 500;
  font-size: 14px;
}

.collection .collection-item.avatar p {
  font-size: 14px;
}

ul.collection {
  border: none;
}

.navbar-fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
}
