.center-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.spinner-color .spinner-blue-only {
  border-color: #424242 !important;
}
