#qr-logo {
  width: 40%;
  max-width: 200px;
  opacity: 70%;
}

#action-qr {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 40px;
}

#wrapper-view {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 50%;
  margin-top: 20px;
}

.area-base {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: cornflowerblue;
}

.wrapper-container {
  display: flex;
  display: -webkit-flex;
  height: calc(100vh - 64px);
}