.oq-group-horizontal,
.oq-group-buttons,
.oq-group {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
}

.oq-group-horizontal.bottom-fixed {
  bottom: 30px;
  position: fixed;
}

.oq-btn {
  transition: 0.27s;
  outline: none !important;
  border: none !important;
  padding: 15px 20px;
  font-size: 14px;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #eee;
}

.oq-btn.full-w {
  width: 100%;
  margin-bottom: 10px;
}

.oq-group-horizontal .oq-btn {
  margin: 0;
  border-radius: 0;
  width: 100%;
}

.oq-group-horizontal .oq-btn.btn-confirm {
  background-color: var(--primary-color);
}

.oq-group-horizontal .oq-btn.btn-confirm:hover {
  background-color: var(--primary-color-dark);
}

.oq-group-horizontal .oq-btn.btn-negative {
  background-color: #e53935;
}

.input-field > label {
  top: -35px !important;
}

.card-panel.outlined {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, .1);
}

.card-panel-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.card-panel-title.big {
  display: flex;
  display: -webkit-flex;
  font-size: 18px;
  align-items: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.card-panel-subtitle {
  font-size: 14px;
}
