#lgn-image-logo {
  width: 70px;
}

#lgn-title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 40px;
}

#lgn-subtitle {
  margin-top: 0;
  font-size: 20px;
}
.clickable {
  cursor: pointer;
}
