.oq-overlay {
  background-color: rgba(255, 255, 255, 0.9);
}

.oq-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.oq-modal {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  min-width: 300px;
  max-width: 450px;
  overflow: hidden;
  padding: 0;
  margin: 20px;
}

.oq-wrapper-contents {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  padding: 30px;
  padding-top: 45px;
}

.oq-center {
  align-items: center;
  text-align: center;
}

.oq-wrapper-contents .oq-alert-icon {
  width: 50px;
  margin-bottom: 20px;
}

.oq-wrapper-contents .title-alert {
  font-size: 25px;
  margin-top: 0;
  font-weight: 500;
  margin-bottom: 5px;
}

.oq-wrapper-contents .content-alert {
  padding: 0.5rem;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.oq-group-horizontal,
.oq-group-buttons,
.oq-group {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
}

.oq-btn {
  transition: 0.27s;
  outline: none !important;
  border: none !important;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #eee;
}

.oq-group-horizontal .oq-btn {
  margin: 0;
  border-radius: 0;
  width: 100%;
}

.oq-group-horizontal .oq-btn.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
}

.oq-group-horizontal .oq-btn.btn-confirm:hover {
  background-color: var(--primary-color-dark);
}

.oq-group-horizontal .oq-btn.btn-negative {
  background-color: #e53935;
  color: #fff;
}

.oq-font-medium {
  font-family: var(--oq-font-medium) !important;
}

.oq-font-book {
  font-family: var(--oq-font-book) !important;
}

.oq-group-horizontal .oq-btn.btn-negative:hover {
  filter: brightness(90%);
}

@media (min-width: 360px) and (max-width: 460px) {
  .oq-modal {
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  .oq-modal {
    padding: 0 !important;
  }
}
