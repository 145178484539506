#business-logo {
  height: 60px;
}

#title-label {
  font-weight: 600;
}

#username {
  font-weight: 600;
}

#user-email {
  font-size: 14px;
}

.container.content {
  padding-bottom: 64px;
}

.material-icons-outlined {
  line-height: unset;
}

.btn-floating.action {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -20px;
  border: 5px solid #fff;
  width: 70px;
  height: 70px;
}

.btn-label {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  flex-direction: column;
  border-radius: 50px;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
}

.btn-label:focus {
  background-color: transparent;
}

.btn-label span {
  position: relative;
  top: -22px;
  font-size: 10px;
}

.count-number {
  font-size: 40px;
  font-weight: 600;
}

.label-card {
  font-size: 12px;
}

.material-icons-outlined.large {
  font-size: 30px;
}
